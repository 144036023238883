import { ReactNode } from 'react';

import { useToastMessage } from '~src/hooks/useToastMessage';

import { defaultLayout } from './layout.module.css';

export const DefaultLayout = ({ children }: { children: ReactNode }) => {
  const { contextHolder } = useToastMessage();

  return (
    <div className={defaultLayout}>
      {contextHolder}

      <main>{children}</main>
    </div>
  );
};
