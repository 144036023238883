import { Divider as AntdDivider, DividerProps as AntdDividerProps } from 'antd';
import clsx from 'clsx';

import { divider, dividerFullWidth } from './divider.module.css';

type DividerProps = AntdDividerProps & {
  fullWidth?: boolean;
};

export const Divider = ({ children, fullWidth, ...rest }: DividerProps) => (
  <AntdDivider {...{ rest }} className={clsx(divider, fullWidth && dividerFullWidth)}>
    {children}
  </AntdDivider>
);
