import { useEffect } from 'react';

import { parseJWT } from '~src/utilities/auth';
import { clearAuthenticationCookies, setAuthenticationCookies } from '~src/utilities/cookie';

import { useLocation } from './useLocation';
import { useRedirectUrl } from './useRedirectUrl';
import { useToastMessage } from './useToastMessage';

export const useExternalAuthProviderCallback = () => {
  const { showMessage } = useToastMessage();
  const { searchParameters, setSearchParameters } = useLocation();
  const { performRedirect } = useRedirectUrl();

  const clearSearchParameters = () => {
    setSearchParameters();
  };

  useEffect(function performLoginOnCallback() {
    const errorParameters = searchParameters.get('error');
    const resultParameters = searchParameters.get('result');

    clearAuthenticationCookies();

    if (errorParameters) {
      showMessage({ type: 'error', message: `Login from external provider failed. ${errorParameters}` });
      clearSearchParameters();
      return;
    }

    if (!resultParameters) {
      clearSearchParameters();
      return;
    }

    const tokens = parseJWT(resultParameters);

    setAuthenticationCookies(tokens);

    clearSearchParameters();

    setTimeout(() => performRedirect());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
