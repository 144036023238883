import clsx from 'clsx';

import { Button, Divider, Form, Heading, Input, Link } from '~src/components/display';
import { LockOutlined, MailOutlined } from '~src/components/display/icons';
import { LoginWithGoogleButton } from '~src/components/features/LoginWithGoogleButton';
import { DefaultLayout } from '~src/components/layouts';
import { useLogin } from '~src/hooks/useLogin';

import { displayColumn, displayRow, spaceBetween } from '~src/style/shared.module.css';

import { loginPage } from './LoginPage.module.css';

export const LoginPage = () => {
  const { login } = useLogin();

  return (
    <DefaultLayout>
      <div className={loginPage}>
        <Heading level={1}>Velkommen til Bodil</Heading>

        <div className={displayColumn}>
          <LoginWithGoogleButton />

          <Divider fullWidth>Eller</Divider>

          <Form onFinish={login}>
            <Form.Item name='email' rules={[{ required: true, type: 'email' }]}>
              <Input prefix={<MailOutlined />} placeholder='Email' />
            </Form.Item>

            <Form.Item name='password' rules={[{ required: true }]}>
              <Input prefix={<LockOutlined />} type='password' placeholder='Password' />
            </Form.Item>

            <div className={clsx(displayRow, spaceBetween)}>
              <Link to='new'>Ny bruger</Link>

              <Link to='reset'>Glemt password</Link>
            </div>

            <Button htmlType='submit' type='primary'>
              Log ind
            </Button>
          </Form>
        </div>
      </div>
    </DefaultLayout>
  );
};
