/* eslint-disable no-console */

export const logger: Pick<typeof console, 'error' | 'warn' | 'log' | 'info' | 'debug'> = {
  debug(data: unknown[]): void {
    console.debug(data);
  },
  error(data: unknown[]): void {
    console.error(data);
  },
  info(data: unknown[]): void {
    console.info(data);
  },
  log(data: unknown[]): void {
    console.log(data);
  },
  warn(data: unknown[]): void {
    console.warn(data);
  },
};
