import { useLoginMutation, useLogoutMutation } from '~src/gql';
import { isAuthenticated } from '~src/utilities/auth';
import { clearAuthenticationCookies, getAuthenticationCookies, setAuthenticationCookies } from '~src/utilities/cookie';
import { captureException } from '~src/utilities/sentry';

import { graphQLClient } from './client';
import { useExternalAuthProviderCallback } from './useAuthProviderCallback';
import { useRedirectUrl } from './useRedirectUrl';
import { useToastMessageErrorHandler } from './useToastMessage';

type LoginCredentials = { email: string; password: string };

export const useLogin = () => {
  const errorHandler = useToastMessageErrorHandler();

  const { setRedirectUrl, performRedirect } = useRedirectUrl();

  const { mutateAsync: loginMutate, isLoading: isLoadingLogin } = useLoginMutation(graphQLClient, {
    onError: errorHandler,
  });
  const { mutateAsync: logoutMutate, isLoading: isLoadingLogout } = useLogoutMutation(graphQLClient, {
    onError: errorHandler,
  });

  setRedirectUrl();
  useExternalAuthProviderCallback();

  const login = async (credentials: LoginCredentials) => {
    try {
      const result = await loginMutate(credentials);
      const { accessToken, refreshToken } = result.login;

      if (!isAuthenticated(accessToken)) {
        return;
      }

      clearAuthenticationCookies();

      setAuthenticationCookies({ accessToken, refreshToken });

      setTimeout(() => performRedirect());
    } catch (caughtError) {
      clearAuthenticationCookies();
      captureException(caughtError);
    }
  };

  const logout = async () => {
    try {
      const { refreshToken } = getAuthenticationCookies();
      if (refreshToken) {
        await logoutMutate({ refreshToken });
      }
    } catch (caughtError) {
      captureException(caughtError);
    } finally {
      clearAuthenticationCookies();
    }
  };

  const isLoading = isLoadingLogin || isLoadingLogout;

  return {
    login,
    logout,
    isLoading,
  };
};
