import { Button } from '~src/components/display';
import { API_URL } from '~src/config';
import { location } from '~src/utilities/global';

const providerLoginURI = (origin: string) => `${API_URL}/rest/v1/auth?provider=google&redirect_uri=${origin}`;

type LoginWithGoogleButtonProps = {
  className?: string;
};

export const LoginWithGoogleButton = ({ className }: LoginWithGoogleButtonProps) => (
  <Button className={className} onClick={() => location.assign(providerLoginURI(location.origin))}>
    Log ind med Google
  </Button>
);
