import { location } from '~src/utilities/global';

import { useLocation } from './useLocation';
import { useToastMessage } from './useToastMessage';

export const useRedirectUrl = () => {
  const { showMessage } = useToastMessage();
  const { searchParameters, setSearchParameters } = useLocation();

  const clearRedirectUrl = () => {
    globalThis.sessionStorage.removeItem('redirectUrl');
  };

  const getRedirectUrl = () => globalThis.sessionStorage.getItem('redirectUrl') ?? undefined;

  const setRedirectUrl = () => {
    const url = searchParameters.get('redirectUrl');
    if (!url) {
      return;
    }

    setSearchParameters();
    const redirectUrl = url.includes('http') ? url : `${location.protocol}//${url}`;
    globalThis.sessionStorage.setItem('redirectUrl', redirectUrl);
  };

  const performRedirect = () => {
    const redirectUrl = getRedirectUrl();

    if (!redirectUrl) {
      showMessage({ type: 'warning', message: 'Ingen redirect' });
      return;
    }

    clearRedirectUrl();
    location.href = redirectUrl;
  };

  return { setRedirectUrl, performRedirect };
};
