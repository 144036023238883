import { lazy, StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { LoginPage } from '~src/components/pages/LoginPage';
import { queryClient, QueryClientProvider } from '~src/hooks/client';
import { initializeSentry } from '~src/utilities/sentry';

import '~src/style/global.css';

const ResetPasswordPage = lazy(() =>
  import('~src/components/pages/ResetPasswordPage').then(module => ({ default: module.ResetPasswordPage }))
);

const router = createBrowserRouter([
  { path: '/', element: <LoginPage /> },
  { path: '/reset', element: <ResetPasswordPage /> },
]);

const LoginApplication = () => (
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <Suspense>
        <RouterProvider router={router} />
      </Suspense>
    </QueryClientProvider>
  </StrictMode>
);

const rootElement = document.querySelector('#login-root');

if (rootElement) {
  initializeSentry();
  createRoot(rootElement).render(<LoginApplication />);
}
