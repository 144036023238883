const decodeBase64String = (data: string) => atob(data);

export const parseJWT = (token: string, index = 0) => {
  const decodedJWT = decodeBase64String(token.split('.')[index]);

  return JSON.parse(decodedJWT);
};

export const isJWTValid = (payload: { iat: number; exp: number }) => {
  const { iat, exp } = payload;
  const now = Math.round(Date.now() / 1000);

  return iat <= now && exp > now;
};

export const isAuthenticated = (accessToken: string) => {
  const payload = parseJWT(accessToken, 1);
  if (!payload) {
    return false;
  }

  return isJWTValid(payload);
};
