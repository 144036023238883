import Cookies, { CookieAttributes } from 'js-cookie';

import { location } from './global';

const getHostDomain = () =>
  location.hostname.includes('localhost') ? location.hostname : `${location.hostname.split('.').slice(1).join('.')}`;

const cookieOptions: CookieAttributes = {
  domain: getHostDomain(),
  expires: 1,
  path: '/',
  sameSite: 'Strict',
  secure: true,
};

const keyWithSuffix = (key: string) => (location?.hostname?.includes('staging') ? `${key}-staging` : key);

type Authentication = {
  accessToken: string;
  refreshToken: string;
};

export const clearAuthenticationCookies = () => {
  Cookies.remove(keyWithSuffix('accessToken'), cookieOptions);
  Cookies.remove(keyWithSuffix('refreshToken'), cookieOptions);

  // clear default cookies
  Cookies.remove(keyWithSuffix('accessToken'));
  Cookies.remove(keyWithSuffix('refreshToken'));
};

export const getAuthenticationCookies = () => ({
  accessToken: Cookies.get(keyWithSuffix('accessToken')),
  refreshToken: Cookies.get(keyWithSuffix('refreshToken')),
});

export const setAuthenticationCookies = ({ accessToken, refreshToken }: Authentication) => {
  Cookies.set(keyWithSuffix('accessToken'), accessToken, cookieOptions);
  Cookies.set(keyWithSuffix('refreshToken'), refreshToken, cookieOptions);
};
